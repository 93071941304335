//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@import "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------


@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/*
 * Example for loading multiple fonts at once
 *
@include googleFonts((
  (
    "name": "Roboto",
    "folder": "Roboto",
    "files": "Roboto",
    "weights": (300, 400, 700)
  ),
  (
    "name": "Besley",
    "folder": "Besley",
    "files": "Besley",
    "weights": (300, 400, 700)
  ),
));
*/

@font-face {
  font-family: "silk-serif";
  src: url('../../../../public/images/fonts/silk-serif/Silk Serif Regular.otf')  format('truetype');
}

.font-family-silk-serif{
  font-family: "silk-serif", serif;
}


//font colors

.font-color-gold {
  color: #A88653;
}

.font-color-grey-dark {
  color: #34302B;
}

.font-color-grey-medium {
  color: #858380;
}

.font-color-brown {
  color: #C58B76;
}

.font-color-theme-800 {
  color: #754037;
}

.font-color-theme-950 {
  color: #331A17;
}

.font-color-theme-300 {
  color: #D0A484;
}

.font-color-theme-500 {
  color: #BC7553;
}

.font-color-theme-600 {
  color: #AE6248;
}

.font-color-light-pallete {
  color: #D9C0A9;
}

.font-color-bg-light-pallete {
  color: #F9EDEB;
}

.font-color-neutral-black {
  color: #0B1019;
}

.font-color-basic-black {
  color: #0C0701;
}

.font-color-white {
  color: #FFFFFFB2;
  opacity: 70%;
}

.font-color-theme-50 {
  color: #FAF5F2;
}


.font-color-black-opacity {
  color: #0C0701B2;
  opacity: 70%;
}

//font sizes

.font-size-88 {
  font-size: 88px;
}
@include media-breakpoint-down(sm) {
  .font-size-88 {
    font-size: 32px;
  }
}

@include media-breakpoint-down(sm) {
  .font-align-center {
    text-align: center;
  }
}

.font-size-18 {
  font-size: 18px;
}

@include media-breakpoint-down(sm) {
  .font-size-18 {
    font-size: 16px;
  }
}

.font-size-48 {
  font-size: 48px;
}

@include media-breakpoint-down(sm) {
  .font-size-48 {
    font-size: 40px;
  }
}

.font-size-14 {
  font-size: 14px;
}

//@include media-breakpoint-down(sm) {
//  .font-size-14 {
//    font-size: 12px;
//  }
//}

.font-size-42 {
  font-size: 42px;
}

@include media-breakpoint-down(sm) {
  .font-size-42 {
    font-size: 24px;
  }
}

.font-size-32 {
  font-size: 32px;
}

@include media-breakpoint-down(sm) {
  .font-size-32 {
    font-size: 20px;
  }
}

// font-weight

.font-weight-600p {
  font-weight: 600;
}

.font-weight-500p {
  font-weight: 500;
}

.font-weight-400p {
  font-weight: 400;
}
