
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

.font-family-raleway{
  font-family: 'Raleway', sans-serif ;
}

body{
  font-family: "silk-serif";
}


//index page

.hero-section-details-container {
  border: 0.5px solid #C58B76;
  color: #C58B76;
  padding: 16px 32px 16px 32px;
  border-radius: 10px;
  gap: 8px;
}


//services

.service-container {
  box-shadow: 15px 15px 0px 0px #696969;
  border-radius: 200px 200px 0px 0px;
}


//about-page

.about-values-container {
  border: 3px solid #A88653;
  border-radius: 200px 200px 0px 0px;
}


//contact-us


.location-contact-us-container {
  border-radius: 10px 0px 0px 10px;
}


//book appointment page


.book-appointment-container {
  margin-top: -150px;
}

.book-appointment-card-container {
  border-radius: 15px;
}

.book-appointment-image {
  border-radius: 15px 0px 0px 15px;
}

@include media-breakpoint-down(sm) {
  .book-appointment-image {
    border-radius: 15px 15px 0px 0px;
  }
}


.input-border-color {
  border-bottom: 1px solid #D9C0A9;
  border-radius: 0;
}


::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #C58B76 !important;
  font-family: 'Raleway', sans-serif !important;
}

//input[type="text"] {
//  color: #C58B76 !important;
//}

select {
  color: #C58B76 !important;
}

input[type="date"] {
  color: #C58B76 !important;
}

input[type="time"] {
  color: #C58B76 !important;
}


.water-mark {
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%); /* Center the text horizontally and vertically */
  font-size: 121px; /* Adjust font size */
  color:#D7B9A04D;
}

.sub-service-border-bottom {
  border-bottom: 1px solid #7775731A;
}

.services-button {
  cursor: pointer;
}


.br-15px {
  border-radius: 15px;
}

.br-top-left-right-500px {
  border-top-left-radius: 500px;
  border-top-right-radius: 500px;
}

.br-top-left-500px {
  border-top-left-radius: 500px;
}

.br-top-right-500px {
  border-top-right-radius: 500px;
}

.br-bottom-right-500px {
  border-bottom-right-radius: 500px;
}

.br-bottom-left-500px {
  border-bottom-left-radius: 500px;
}

.hero-section-light-image {
  margin-top: -150px;
  margin-left: 200px;
}

.hero-section-reception-image {
  margin-top: -180px;
  margin-left: 100px;
}

.hero-section-make-up-area-image {
  margin-top: -150px;
  margin-left: 100px;
}
