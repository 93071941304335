.button-bg {
  padding: 8px 16px 8px 16px;
  border-radius: 15px;
  background: #A88653;
  border: none;
}


.button-explore-all {
  box-shadow: 4px 4px 4px 0px #D0A4841A inset;
  box-shadow: -4px -4px 4px 0px #D0A4841A inset;
}


.button-see-details {
  padding: 8px 16px 8px 16px;
  border-radius: 15px;
  background: #C58B76;
  box-shadow: 4px 4px 4px 0px #D0A4841A inset;
  box-shadow: -4px -4px 4px 0px #D0A4841A inset;
  border: none;
}


.button-reviews-left-right-arrow {
  background: #A88653;
  border: none;
}

.button-services-left-right-arrow {
  background: #D9C0A9;
  border: none;
}
