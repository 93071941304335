//navbar

.bg-color-navbar {
  background: #D9C0A9;
}


.services-container:hover {
  .services-details {
    display: block !important;
  }
}

//hero section

.bg-hero-section-container {
  background-image: url('../../../../public/images/landing/bg-salon-hero-section-image.png'); /* Adjust as per your requirements */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-color: #F9EDEB;

}

@include media-breakpoint-down(sm) {
  .bg-hero-section-container {
    background-image: none;
  }
}

.bg-color-container {
  background-color: #F9EDEB;
}

.bg-services-card-container {
  background-color: #EBCFC3;
}

.bg-appointment-book-container {
  background-image: url("../../../../public/images/landing/bg-book-appointment-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}


// service details page

.bg-services-hero-section {
  //background-image: url("../../../../public/images/services/bg-services-hero-section-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-hair-image-container {
  margin-top: -150px;
}

@include media-breakpoint-down(sm) {
  .bg-hair-image-container {
    margin-top: -50px;
  }
}


//reviews container

.bg-customer-review-container {
  background-image: url("../../../../public/images/landing/bg-image-customer-reviews.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}


.bg-reviews-container {
  background: linear-gradient(0deg, rgba(217, 192, 169, 0.5), rgba(217, 192, 169, 0.5));
  border: 1px solid #D9C0A9;
  border-radius: 10px;
}


//contact-us

.bg-contact-us-container {
  background-image: url("../../../../public/images/contact-us/bg-contact-us-hero-section-image.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-contact-us-card-container {
  background-image: url("../../../../public/images/contact-us/bg-write-us-image.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.write-us-container {
  background-color: #E6D2B9;
}

.contact-us-img-container {
  margin-top: -10px;
}

//book appointment page

.bg-book-appointment-container {
  background-image: url("../../../../public/images/book-appointment/bg-book-appontment-bg-image.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.line-book {
  border: 1px solid #D9C0A9
}

//footer

.bg-footer-container {
  background-color: #2C2C2C;
}

.footer-logo-container {
  background: linear-gradient(0deg, #FFEBD6, #FFEBD6),
  linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));

}


//about page

.bg-who-are-we-container {
  background-color: #D9C0A9;
}


.bg-who-are-we-card-container {
  background-color: #A88653;
}

.bg-values-container {
  background-color: #EBCFC3;
}


.bg-values-card-container {
  background-color: #C58B76;
}

.bg-beige-dark {
  background-color: #AB957D;
}
